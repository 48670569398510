body {
  font-family: Arial, sans-serif;
  margin: 5rem 0;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #0d2438;
  color: white;
  font-size: 20px;
  max-width: 100%;
  overflow-x: hidden;
}

header {
  background-color: #0d2438;
  color: white;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  height: 5rem;
  top: 0;
  /* border-bottom: 1px solid #ddd; */
}

nav a {
  color: white;
  margin: 0 2rem;

  &:hover {
    text-decoration: underline;
  }
}

.book-card {
  border: 1px solid #ddd;
  padding: 1rem;
  margin: 2rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 400px 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: left;
    justify-content: center;
    padding: 1.5rem;
    margin: 0.5rem;
    width: 90%;
  }

  &.book-card-image {
    grid-area: 0 / 1;
    justify-self: center;
    align-items: center;

    /* Mobile */
    @media (max-width: 768px) {
      grid-area: 0 / 2;
    }
  }

  &.book-card-details {
    grid-area: 1 / 2;
    justify-content: center;
    align-items: center;

    /* Mobile */
    @media (max-width: 768px) {
      grid-area: 0 / 2;
    }
  }
}

.author-card-section {
  display: grid;
  grid-template-columns: 499px 1fr;
  gap: 20px;
  align-items: center;
  margin: 4rem;

  @media (max-width: 1066px) {
    display: inline;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.author-buttons {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem;

  @media (max-width: 768px) {
    display: block;
    margin: 1rem;
  }
}

.header-button {
  color: white;
  padding: 1.5rem 2rem;
  border: 1px solid white;
  border-radius: 50px;
  margin-right: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  @media (max-width: 768px) {
    display: flex;
    margin: 1rem;
  }

  &:hover {
    background-color: white;
    color: #0d2438;
  }
}

.book-card h2 {
  margin-top: 0;
  color: white;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
}

.tom-h1 {
  margin-left: 2rem;
  text-decoration: none;
}

.nav-title {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  text-decoration: none;
  margin-left: 2rem;
  padding: 1rem;
  font-family: 'Trebuchet MS', sans-serif;
}

a {
  color: white;
  text-decoration: none;
}

.buy-amazon-button {
  background-color: #f90;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}

footer {
  background-color: #0d2438;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  max-width: 100%;
  width: 100%;
  height: fit-content;
}
